import React from 'react'
import { css } from '@emotion/react'

import {
  AnchorWithArrow,
  AnchorWithArrowProps
} from '@/components/common/anchor'
import { mq } from '@/components/media/media'
import { Color } from '@/typedir'

export type GradientButtonProps = AnchorWithArrowProps & {
  gradientColor: [Color, Color]
  className?: string
}

export const GradientButton = (props: GradientButtonProps): JSX.Element => {
  const { gradientColor, children, className, ...others } = props

  return (
    <AnchorWithArrow
      css={cssGradientButton(gradientColor)}
      className={className}
      {...others}
    >
      {children}
    </AnchorWithArrow>
  )
}

const cssGradientButton = (gradientColor: [Color, Color]) => css`
  width: 100%;
  max-width: 280px;
  height: 52px;
  font-size: 16px;
  letter-spacing: 1px;
  background-image: linear-gradient(
    100deg,
    ${gradientColor[0]},
    ${gradientColor[1]}
  );
  border: none;
  border-radius: 8px;
  &:hover {
    opacity: 0.85;
  }
  ${mq.pc} {
    max-width: 320px;
    height: 64px;
    font-size: 18px;
    border-radius: 12px;
  }
`
