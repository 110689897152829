import React from 'react'
import { css } from '@emotion/react'

import { Anchor } from '@/components/common/anchor'

type Props = {
  url: string
  imgSrc: string
  alt: string
  className?: string
}

export const Logo: React.FC<Props> = ({ url, imgSrc, alt, className }) => (
  <Anchor
    css={cssSPLogoWrapper}
    href={url}
    aria-label="トップページへ"
    className={className}
  >
    <img src={imgSrc} alt={alt} />
  </Anchor>
)

const cssSPLogoWrapper = css`
  display: block;
`
