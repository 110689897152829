import React from 'react'

type Props = {
  className?: string
  onClick?: () => void
  ariaLabel: string
}

export const HeaderToggleButton: React.FC<Props> = ({
  className,
  onClick,
  ariaLabel
}) => (
  <button className={className} onClick={onClick} aria-label={ariaLabel}>
    <span></span>
    <span></span>
    <span></span>
  </button>
)
