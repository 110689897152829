import React from 'react'
import { css, SerializedStyles } from '@emotion/react'
import color from '@/constants/color'

import { GradientButton } from '@/components/common/anchor/gradient-button'
import { HeaderNavListType } from '@/typedir/types'
import { HeaderNavLink } from './header-nav-link'

type Props = {
  navList: HeaderNavListType[]
  gradientColor: [string, string]
  btnFontStyleCss?: SerializedStyles
  arrowFillColor: string
  onClick?: () => void
  breakpoint: number
}

export const HeaderNavList: React.FC<Props> = ({
  navList,
  gradientColor,
  btnFontStyleCss,
  arrowFillColor,
  onClick,
  breakpoint
}) => {
  return (
    <nav css={cssNav(breakpoint)}>
      <ul css={cssNavList(breakpoint)}>
        {navList.map((navItem, index) => {
          if ('isContact' in navItem) {
            return (
              <li key={index} css={cssContactButtonWrapper(breakpoint)}>
                <GradientButton
                  gradientColor={gradientColor}
                  href={navItem.url}
                  arrowFillColor={[arrowFillColor]}
                  css={[cssContactButton(breakpoint), btnFontStyleCss]}
                >
                  お問い合わせ
                </GradientButton>
              </li>
            )
          }
          return (
            <li key={index} css={cssNavItem}>
              <HeaderNavLink
                href={navItem.url}
                onClick={onClick}
                label={navItem.label}
                labelEnglish={navItem.labelEnglish}
                breakpoint={breakpoint}
              />
            </li>
          )
        })}
      </ul>
    </nav>
  )
}

const cssNav = (breakpoint: number) => css`
  margin-top: 8px;
  @media (min-width: ${breakpoint}px) {
    margin: 0;
  }
`

const cssNavList = (breakpoint: number) => css`
  display: flex;
  flex-direction: column;
  width: 100vw;
  @media (min-width: ${breakpoint}px) {
    flex-direction: row;
    align-items: center;
    width: auto;
  }
`

const cssNavItem = css`
  text-align: center;
`

const cssContactButtonWrapper = (breakpoint: number) => css`
  padding: 20px 16px 0;
  text-align: center;
  @media (min-width: ${breakpoint}px) {
    padding: 0;
    margin-left: 20px;
  }
`

const cssContactButton = (breakpoint: number) => css`
  margin: 0 auto;
  box-shadow: 0 6px 8px ${color.black4};
  @media (min-width: ${breakpoint}px) {
    height: 100%;
    padding: 10px 22px;
    font-size: 16px;
    line-height: 1.6;
    border-radius: 8px;
    box-shadow: none;
  }
`
