import React from 'react'
import { Link } from 'gatsby'

export type AnchorBaseProps = {
  children: React.ReactNode
  isTargetBlank?: boolean
  href: string
  className?: string
  onClick?: (args: unknown) => unknown
}

export const Anchor = (props: AnchorBaseProps): JSX.Element => {
  const { href, children, isTargetBlank = false, ...others } = props

  if (href.includes('http') || isTargetBlank) {
    return (
      <a href={href} target="_blank" rel="noopener noreferrer" {...others}>
        {children}
      </a>
    )
  }

  return (
    <Link to={href} {...others}>
      {children}
    </Link>
  )
}
