import React from 'react'
import { css, SerializedStyles } from '@emotion/react'
import { Anchor, AnchorBaseProps } from '@/components/common/anchor'
import { cssFlexCenter } from '@/styles/general'
import { Color } from '@/typedir'
import { SvgArrow } from '@/components/svg'

export type AnchorWithArrowProps = AnchorBaseProps & {
  arrowFillColor: Color[]
  className?: string
  ArrowWrapperCss?: SerializedStyles
}

export const AnchorWithArrow = ({
  arrowFillColor,
  children,
  className,
  ArrowWrapperCss,
  ...others
}: AnchorWithArrowProps): JSX.Element => {
  return (
    <Anchor css={cssFlexCenter} className={className} {...others}>
      {children}
      {others.href && others.href.includes('http') && (
        <div css={[cssArrowWrapper, ArrowWrapperCss]}>
          <SvgArrow label="外部ページへのリンク" fillColor={arrowFillColor} />
        </div>
      )}
    </Anchor>
  )
}

const cssArrowWrapper = css`
  display: flex;
  align-items: center;
  width: 12px;
  margin-left: 6px;
`
