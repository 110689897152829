const color = {
  black0: '#000',
  black1: '#333',
  black2: '#212121',
  black3: '#0000001F',
  black4: '#00000033',
  black5: '#00000029',
  black6: '#1e120d',
  gray1: '#CCCCCC',
  gray2: '#fafafa',
  gray3: '#F4F5F7',
  gray4: '#707070',
  gray5: '#b0afb5',
  gray6: '#575757',
  gray7: '#606060',
  white1: '#fff',
  white2: 'rgba(255,255,255,.98)',
  white3: 'rgba(255,255,255,.95)',
  white4: '#F4F4F4',
  blue1: '#213EAF',
  blue2: '#122D67',
  blue3: '#112C62',
  blue4: '#024874',
  blue5: 'rgba(2,72,116,.14)',
  blue6: '#007CFF',
  blue7: '#2340b7',
  blue8: '#0071C5',
  blue9: '#009fb9',
  green1: '#44D62C',
  green2: '#1EBA55',
  green3: '#8CDC50',
  green4: '#008741',
  orange1: '#E0441A',
  orange2: '#F64744',
  orange3: '#E06C22',
  orange4: '#FFA300',
  gold1: '#C0A64D',
  lightOrange1: '#E0441A',
  lightOrange2: '#F64744',
  red1: '#E0441A1A',
  red2: '#FF0000',
  yellow1: '#E3E829',
  beige1: '#F3F1EF',
  purple1: '#6128ED',
  purple2: '#3F1C7D',
  tp: 'transparent'
} as const

export default color
