import color from '@/constants/color'
import { Color } from '@/typedir'
import React from 'react'

export type SvgMoreProps = {
  fillColor?: Color[]
  className?: string
}

export const SvgMore = ({
  fillColor = [color.blue7, color.blue3, color.white1],
  className
}: SvgMoreProps): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18 18"
    className={className}
  >
    <defs>
      <linearGradient
        id="gradation"
        x1="-21.15"
        y1="776.61"
        x2="-21.15"
        y2="775.61"
        gradientTransform="matrix(-18, 0, 0, 18, -371.72, -13960.9)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0%" stopColor={fillColor[0]} />
        <stop offset="100%" stopColor={fillColor[1]} />
      </linearGradient>
    </defs>
    <g id="レイヤー_2" data-name="レイヤー 2">
      <g id="レイヤー_1-2" data-name="レイヤー 1">
        <g id="グループ_333" data-name="グループ 333">
          <circle
            id="楕円形_2452"
            data-name="楕円形 2452"
            fill="url(#gradation)"
            cx="9"
            cy="9"
            r="9"
          />
          <polygon
            fill={fillColor[2]}
            points="8.18 12.76 6.76 11.35 9.11 9 6.76 6.65 8.18 5.24 11.94 9 8.18 12.76"
          />
        </g>
      </g>
    </g>
  </svg>
)
