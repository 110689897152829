import React, { useState } from 'react'
import { css, SerializedStyles } from '@emotion/react'

import color from '@/constants/color'
import { Logo } from '@/components/common/logo'
import { HeaderNavList } from '@/components/section/header/header-nav-list'
import { HeaderNavListType } from '@/typedir/types'
import { HeaderToggleButton } from '@/components/section/header/header-toggle-button'

type Props = {
  className?: string
  url: string
  imgSrc: string
  alt: string
  headerLogoCss: SerializedStyles
  navList: HeaderNavListType[]
  gradientColor: [string, string]
  btnFontStyleCss?: SerializedStyles
  arrowFillColor: string
  breakpoint?: number
}

export const HeaderNav: React.FC<Props> = ({
  className,
  url,
  imgSrc,
  alt,
  headerLogoCss,
  navList,
  gradientColor,
  btnFontStyleCss,
  arrowFillColor,
  breakpoint = 1080
}) => {
  const [isOpened, setIsOpened] = useState(false)

  const onClickToggleMenu = () => {
    setIsOpened(!isOpened)
  }

  return (
    <>
      <header css={cssHeaderWrapper(breakpoint)} className={className}>
        <Logo url={url} imgSrc={imgSrc} alt={alt} css={headerLogoCss} />
        <div
          css={cssSPMenuWrapper(isOpened, breakpoint)}
          aria-hidden={!isOpened}
        >
          <div css={cssSPTopWrapper(breakpoint)}>
            <Logo url={url} imgSrc={imgSrc} alt={alt} css={headerLogoCss} />
            <button
              css={cssCloseMenuButton(breakpoint)}
              onClick={onClickToggleMenu}
              aria-label="メニューを閉じる"
            />
          </div>
          <HeaderNavList
            onClick={onClickToggleMenu}
            gradientColor={gradientColor}
            btnFontStyleCss={btnFontStyleCss}
            arrowFillColor={arrowFillColor}
            navList={navList}
            breakpoint={breakpoint}
          />
        </div>
        <HeaderToggleButton
          css={cssHamburger(breakpoint)}
          onClick={onClickToggleMenu}
          ariaLabel="メニューの移動による表示切り替えトグル"
        />
      </header>
    </>
  )
}

const cssHamburger = (breakpoint: number) => css`
  position: relative;
  width: 24px;
  height: 18px;
  @media (min-width: ${breakpoint}px) {
    display: none;
  }
  span {
    position: absolute;
    top: 8px;
    width: 100%;
    height: 2px;
    background: ${color.black0};
    border-radius: 1px;
    &:nth-child(1) {
      transform: translateY(-9px);
    }
    &:nth-child(3) {
      transform: translateY(9px);
    }
  }
`

const cssCloseMenuButton = (breakpoint: number) => css`
  position: relative;
  width: 24px;
  height: 18px;
  @media (min-width: ${breakpoint}px) {
    display: none;
  }
  &::before,
  &::after {
    position: absolute;
    top: 8px;
    width: 100%;
    height: 2px;
    content: '';
    background: ${color.black0};
    border-radius: 1px;
  }
  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }
`

const cssHeaderWrapper = (breakpoint: number) => css`
  position: fixed;
  top: 16px;
  left: 50%;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100vw - 32px);
  background-color: ${color.white2};
  border-radius: 6px;
  transform: translate(-50%);
  @media (min-width: ${breakpoint}px) {
    top: 40px;
    max-width: 1080px;
    padding: 12px 24px;
  }
`

const cssSPMenuWrapper = (isOpened: boolean, breakpoint: number) => css`
  position: absolute;
  top: -16px;
  left: -16px;
  z-index: 1;
  height: 100vh;
  padding: 24px 0;
  background-color: ${color.white1};
  transition: 0.8s ease;
  transform: ${isOpened ? 'translateY(0)' : 'translateY(-100vh)'};
  @media (min-width: ${breakpoint}px) {
    position: relative;
    top: auto;
    left: auto;
    height: auto;
    padding: 0;
    background: none;
    transform: none;
  }
`

const cssSPTopWrapper = (breakpoint: number) => css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 32px;
  @media (min-width: ${breakpoint}px) {
    display: none;
  }
`
