import React from 'react'
import { AnchorWithArrow } from '@/components/common/anchor'
import { css, SerializedStyles } from '@emotion/react'
import color from '@/constants/color'
import { mq } from '@/components/media/media'

type Props = {
  footerBottomList: { url: string; label: string }[]
  copy?: string
  className?: string
  copyrightLayoutCss?: SerializedStyles
}

export const FooterBottom: React.FC<Props> = ({
  footerBottomList,
  copy,
  className,
  copyrightLayoutCss
}) => (
  <div css={cssCopyrightWrapper} className={className}>
    <div css={[cssCopyrightLayout, copyrightLayoutCss]}>
      <div css={cssFooterBottomList}>
        {footerBottomList.map((footerBottomItem, index) => (
          <AnchorWithArrow
            key={index}
            href={footerBottomItem.url}
            css={cssFooterBottomLink}
            arrowFillColor={[color.gray6]}
          >
            {footerBottomItem.label}
          </AnchorWithArrow>
        ))}
      </div>
      {copy && <p css={cssCopyright}>{copy}</p>}
    </div>
  </div>
)

const cssCopyrightWrapper = css`
  padding: 0 16px;
  background-color: ${color.white4};
`

const cssCopyrightLayout = css`
  padding: 24px 0 12px;
  color: ${color.black1};
  ${mq.pc} {
    display: flex;
    justify-content: space-between;
    max-width: 1080px;
    padding-top: 12px;
    margin: 0 auto;
  }
`

const cssFooterBottomList = css`
  ${mq.pc} {
    display: flex;
    justify-content: space-between;
  }
`

const cssFooterBottomLink = css`
  margin-bottom: 12px;
  font-size: 12px;
  font-weight: 300;
  text-align: center;
  letter-spacing: 1.2px;
  ${mq.pc} {
    padding-right: 30px;
    margin: 0;
  }
`

const cssCopyright = css`
  margin-top: 24px;
  font-size: 10px;
  font-weight: 300;
  color: ${color.black1};
  text-align: center;
  letter-spacing: 1px;
  ${mq.pc} {
    margin: 0;
    font-size: 12px;
  }
`
