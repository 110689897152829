import color from '@/constants/color'
import { Color } from '@/typedir'
import React from 'react'

export type SvgArrowProps = {
  fillColor?: Color[]
  label?: string
  className?: string
}

export const SvgArrow = ({
  className,
  label,
  fillColor = [color.gray6]
}: SvgArrowProps): JSX.Element => {
  const id = `arrow-${Math.random()}`
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 12 12"
      aria-labelledby={id}
    >
      <title id={id}>{label}</title>
      <g id="レイヤー_2" data-name="レイヤー 2">
        <g id="レイヤー_1-2" data-name="レイヤー 1">
          <g id="レイヤー_2-2" data-name="レイヤー 2">
            <g id="Rectangle">
              <rect id="rect" fill={color.tp} width="12" height="12" />
            </g>
            <g id="icon_data" data-name="icon data">
              <path
                fill={fillColor[0]}
                d="M10.56,5.92a.76.76,0,0,1-.75-.75v-3h-3a.75.75,0,0,1,0-1.5h3.73a.74.74,0,0,1,.75.75V5.17A.75.75,0,0,1,10.56,5.92Z"
              />
              <path
                fill={fillColor[0]}
                d="M8.9,11.31H1.44a.74.74,0,0,1-.75-.75V3.1a.74.74,0,0,1,.75-.75H4.76a.75.75,0,0,1,0,1.5H2.19v6h6V7.24a.75.75,0,0,1,1.5,0v3.32A.74.74,0,0,1,8.9,11.31Z"
              />
              <path
                fill={fillColor[0]}
                d="M5.41,7.34a.79.79,0,0,1-.53-.22.75.75,0,0,1,0-1.06l5-5a.74.74,0,0,1,1.06,0,.74.74,0,0,1,0,1.06l-5,5A.75.75,0,0,1,5.41,7.34Z"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
