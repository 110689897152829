import React from 'react'
import { AnchorWithArrow } from '@/components/common/anchor'
import { css } from '@emotion/react'
import color from '@/constants/color'
import { mq } from '@/components/media/media'
import { cssFlexColumn, cssFlexJustifyCenter, cssGap } from '@/styles/general'

type Props = {
  href: string
  label: string
  labelEnglish: string
  onClick?: () => void
  breakpoint: number
}

export const HeaderNavLink: React.FC<Props> = ({
  href,
  label,
  labelEnglish,
  onClick,
  breakpoint
}) => (
  <AnchorWithArrow
    href={href}
    css={cssNavLink(breakpoint)}
    arrowFillColor={[color.gray6]}
    ArrowWrapperCss={cssArrowMargin}
    onClick={onClick}
  >
    <span css={[cssFlexColumn, cssFlexJustifyCenter, cssGap(4)]}>
      <span css={cssLabel(breakpoint)}>{label}</span>
      <span css={cssLabelEnglish(breakpoint)}>{labelEnglish}</span>
    </span>
  </AnchorWithArrow>
)

const cssNavLink = (breakpoint: number) => css`
  padding: 12px 0;
  @media (min-width: ${breakpoint}px) {
    padding: 0 20px;
    line-height: 1.3;
  }
`

const cssArrowMargin = css`
  margin-bottom: -24px;
  ${mq.pc} {
    margin-bottom: -18px;
    margin-left: 2px;
  }
`

const cssLabel = (breakpoint: number) => css`
  font-size: 12px;
  font-weight: 600;
  color: ${color.black1};
  letter-spacing: 1.2px;
  @media (min-width: ${breakpoint}px) {
    font-weight: 400;
  }
`

const cssLabelEnglish = (breakpoint: number) => css`
  font-size: 28px;
  font-weight: 500;
  color: ${color.black2};
  letter-spacing: 2px;
  @media (min-width: ${breakpoint}px) {
    font-size: 15px;
  }
`
